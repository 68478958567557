<template>
  <div>
    <el-link type='primary' @click='jumpToDetail(userInfo)' v-if='userPermissions.indexOf("user_detail")>-1'>
      {{ userInfo.nickname || ' -- ' }}
    </el-link>
    <span v-else>{{ userInfo.nickname || ' -- ' }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserNameTag',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    jumpToPage(pageName, params) {
      this.$router.push({
        name: pageName,
        params: params
      })
      // const { href } = this.$router.resolve({
      //   name: pageName,
      //   params: params
      // })
      // window.open(href, '_blank')
    },
    jumpToDetail(row) {
      this.jumpToPage('UserDetail', { user_id: row.id })

    }
  }
}
</script>

<style scoped>

</style>