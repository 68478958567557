<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <el-card>
          <div style='width: 100%;text-align: center'>
            <el-link style='font-weight: bold;font-size: 28px'
                     href='https://open.dingtalk.com/document/orgapp/obtain-multiple-employee-demission-information'
                     target='_blank'>离职人员名单
            </el-link>
          </div>
        </el-card>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='钉钉ID'>
              <el-input v-model='searchCondition.userid' clearable></el-input>
            </el-form-item>
            <el-form-item label='钉钉昵称'>
              <el-input v-model='searchCondition.name' clearable></el-input>
            </el-form-item>
            <el-form-item label='离职前工作状态'>
              <el-select v-model='searchCondition.pre_status' style='width: 140px!important;' placeholder='状态' clearable>
                <el-option :value='val' :label='label' v-for='(label,val) in preStatusMap' :key='val'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='离职状态'>
              <el-select v-model='searchCondition.status' style='width: 140px!important;' placeholder='状态' clearable>
                <el-option :value='val' :label='label' v-for='(label,val) in statusMap' :key='val'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='getList'>搜索</el-button>
              <el-button type='warning' icon='el-icon-search' @click='syncData'>同步离职信息</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='default-table'>
          <!--    渲染表格-->
          <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                   :loading='loadingStatus' border>
            <el-table-column label='最后工作日' align='center' prop='last_work_day' width='200'>
              <template slot-scope='{row}'>
                <div>
                  <span>{{ $utils.parseTime(row.last_work_day) }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label='创建时间' prop='created_at' align='center' width='140'></el-table-column>
            <el-table-column label='修改时间' prop='updated_at' align='center' width='140'></el-table-column>
            <el-table-column label='操作' width='160' align='center'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-view' @click='showDetail(row)'>详情</el-button>
                <el-button v-if='row.status===3' type='text' icon='el-icon-refresh'
                           @click='handleCalcLeaveStatus(row.userid,$index)'>纠正
                </el-button>
                <!--              <el-button type='text' icon='el-icon-delete' @click='handleCancel(row)'>删除</el-button>-->
              </template>
            </el-table-column>
          </m-table>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>
    </page-header-layout>
    <el-dialog
      title='离职申请详情'
      :visible.sync='dialogVisible'
      width='50%'
      center>

      <div>
        <leave-employee-info :info='currRow' />
      </div>
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--      <el-button @click="dialogVisible = false">取 消</el-button>-->
      <!--      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
      <!--    </span>-->
    </el-dialog>
  </div>
</template>

<script>


import LeaveEmployeeInfo from '@/pages/team/dept/LeaveEmployeeInfo'
import UserNameTag from '@/pages/user/components/UserNameTag'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'DepartList',
  components: { PageHeaderLayout, UserNameTag, LeaveEmployeeInfo },
  data() {
    return {
      loadingStatus: false,
      searchCondition: {},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '钉钉用户ID',
          value: 'userid',
          width: 120
        },
        {
          title: '钉钉昵称',
          value: 'name',
          width: 120
        },
        {
          title: '入职时间',
          value: 'hired_at',
          width: 120
        },
        {
          title: '离职前主部门',
          value: 'main_dept_name',
          width: 120
        }, {
          title: '离职前主部门名称',
          value: 'dept_path',
          width: 120
        },
        {
          title: '离职原因',
          value: 'reason_memo',
          width: 200
        },
        {
          title: '离职前工作状态',
          value: 'pre_status_alias',
          width: 100
        }, {
          title: '离职状态',
          value: 'status_alias',
          width: 100
        }
        // {
        //   title: '部门详情',
        //   value: 'dept_list',
        //   width: 300
        // },
        // {
        //   title: '创建时间',
        //   value: 'created_at',
        //   width: 120
        // },
        // {
        //   title: '修改时间',
        //   value: 'updated_at',
        //   width: 120
        // }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      eventTypeArr: ['check_url', 'user_modify_org'],
      dealStatusMap: { 1: '待处理', 2: '处理中', 3: '成功', 4: '失败' },
      statusMap: { 1: '待离职', 2: '已离职', 3: '未离职', 4: '发起离职审批但还未通过', 5: '失效（离职流程被其他流程强制终止后的状态）' },
      preStatusMap: { 1: '待入职', 2: '试用期', 3: '正式' },
      currRow: {},
      dialogVisible: false
    }
  },
  methods: {
    async handleCalcLeaveStatus(userid,$index) {
      if (userid) {
        let data = await this.$api.calcLeaveStatus(userid)
        this.$notify.info(JSON.stringify(data))
        this.dataList.splice($index,1)
        // await this.getList()
      } else {
        this.$notify.warning('参数不读取')
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.userid) {
        condition['userid'] = this.searchCondition.userid
      }
      if (this.searchCondition.name) {
        condition['name'] = this.searchCondition.name
      }
      if (this.searchCondition.status) {
        condition['status'] = this.searchCondition.status
      }
      if (this.searchCondition.pre_status) {
        condition['pre_status'] = this.searchCondition.pre_status
      }
      if (this.searchCondition.date_range) {
        condition['date_range'] = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData)
      let { list, pages } = await this.$api.getUserLeaveList(searchCondition)
      list.map((item) => {
        let deptList = item.dept_list ? JSON.parse(item.dept_list) : []
        let dept_path_arr = deptList.map((item) => {
          return item.dept_path
        })
        item['dept_path'] = dept_path_arr.join('、')
        //转义
        item['pre_status_alias'] = this.preStatusMap[item['pre_status']] ? this.preStatusMap[item['pre_status']] : item['pre_status']
        item['status_alias'] = this.statusMap[item['status']] ? this.statusMap[item['status']] : item['status']
      })
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })
    },
    showDetail(row) {
      this.currRow = { ...row }
      this.dialogVisible = true
    },
    async handleCancel(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let id = await this.$api.delLeaveInfo(row.id)
        if (id) {
          this.$notify.success('删除成功')
          await this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })


    },
    syncData() {
      //查看离职人员
      this.$notify.warning('开发中')

    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>