<template>
  <div>
    <el-card>
      <div slot='header' class='clearfix'>
        <span>离职信息</span> 【
        <el-link href='https://open.dingtalk.com/document/isvapp/obtain-multiple-employee-demission-information-1'
                 target='_blank'>批量获取员工离职信息
        </el-link>
        】
      </div>
      <el-descriptions title=''>
        <el-descriptions-item label='离职原因'>{{ info.reason_memo }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title=''>
        <el-descriptions-item label='离职前工作状态'>{{statusMap[info.pre_status] }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title=''>
        <el-descriptions-item label='离职状态'>{{statusMap[info.status] }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title=''>
        <el-descriptions-item label='离职前主部门名称'>{{ info.main_dept_name }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title=''>
        <el-descriptions-item label='部门路径'>
          <ul v-if='deptList'>
            <li v-for='dept in deptList' :key='dept.dept_id'>{{ dept.dept_path }}</li>
          </ul>
        </el-descriptions-item>
      </el-descriptions>
      <div>
        <el-divider posiiton='center'>钉钉原始数据
          <el-link type='primary' @click='showOrgData'><i
            :class="[this.showOrgView?'el-icon-arrow-up':'el-icon-arrow-down']"></i></el-link>
        </el-divider>
        <div v-show='this.showOrgView'>
          <el-link type='primary' icon='el-icon-view' title='获取最新离职申请' @click='getLastInfo'>获取最新离职信息</el-link>
          <div v-for='(item,index) in currLeaveInfos' :key='index'>
            <div>离职前工作状态：{{ preStatusMap[item.pre_status] }}-{{ item.pre_status }}</div> &nbsp;&nbsp;
            <div>离职状态：{{ statusMap[item.status] }}-{{ item.status }}</div>&nbsp;&nbsp;
            <div>离职前主部门名称：{{ item.main_dept_name }}</div>&nbsp;&nbsp;
            <div>部门路径：
              <span v-if='item.dept_list'>
                <span v-for='dept in item.dept_list' :key='dept.dept_id'>{{ dept.dept_path }}</span>
              </span>
            </div>
            <el-button v-if='item.status===3' type='primary' @click='checkLeaveStatus'>确认未离职</el-button>
          </div>

          <!--          <div>-->
          <!--            {{ JSON.stringify(currLeaveInfos, null, 4) }}-->
          <!--          </div>-->
        </div>
      </div>
    </el-card>


  </div>
</template>

<script>
export default {
  name: 'LeaveEmployeeInfo',
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    deptList() {
      if (this.info.dept_list) {
        if (typeof this.info.dept_list === 'string') {
          return JSON.parse(this.info.dept_list)
        } else if (typeof this.info.dept_list === 'object') {
          return this.info.dept_list
        }
      }
      return this.info.dept_list
    }
  },
  watch: {
    info: {
      deep: true,
      handler() {
        this.currLeaveInfos = []
      }
    }
  },
  data() {
    return {
      showOrgView: false,
      currLeaveInfos: [],
      statusMap: { 1: '待离职', 2: '已离职', 3: '未离职', 4: '发起离职审批但还未通过', 5: '失效（离职流程被其他流程强制终止后的状态）' },
      preStatusMap: { 1: '待入职', 2: '试用期', 3: '正式' }
    }
  },
  methods: {
    showOrgData() {
      this.showOrgView = !this.showOrgView
    },
    async getLastInfo() {
      this.$notify.info('获取最新数据')
      this.currLeaveInfos = []
      let { list } = await this.$api.getDimissionUserInfo(this.info.userid)

      this.currLeaveInfos = [...list]
    },
    async checkLeaveStatus() {
      if (this.info.userid) {
        let data = await this.$api.calcLeaveStatus(this.info.userid)
        this.$notify.info(JSON.stringify(data))
        await this.getLastInfo()
      } else {
        this.$notify.warning('参数不读取')
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>